@use "sass:meta" as ---ullwtt3r34q;$facebook_color: #4267b2;
$instagram_color: #f77737;
$telegram_color: #08c;
$vk_color: #2787f5;
$dzen_color: #000;
$odnoklassniki_color: #faab62;
$pinterest_color: #e60023;
$tumblr_color: #314358;
$twitter_color: #000;

.subscribe-tizer {
  @include grid-column-margin;

  background-color: $alice-blue;
  clear: both;
  padding: $global-padding;

  @media print {
    display: none !important;
  }

  .media-object {
    align-items: center;
    margin-bottom: 0;
  }

  .media-object-section {
    &.logo {
      line-height: 0;
    }

    &.description {
      margin-right: $global-margin;

      @include breakpoint(small only) {
        text-align: center;
      }

      span {
        font-size: rem-calc(13);
      }
    }
  }

  .action {
    @include breakpoint(small only) {
      margin-top: $global-margin;
    }
  }

  .button {
    @include color-mixin($white);

    margin: 0;
    outline: none;
    text-decoration: none;
    font-size: rem-calc(15);

    &:visited {
      @include color-mixin($white);
    }
  }

  &.facebook {
    background-color: transparentize($facebook_color, .85);

    .button {
      background-color: $facebook_color;

      &:hover {
        background-color: darken($facebook_color, 10%);
      }
    }
  }

  &.instagram {
    background-color: transparentize($instagram_color, .85);

    .button {
      background-color: $instagram_color;

      &:hover {
        background-color: darken($instagram_color, 10%);
      }
    }
  }

  &.telegram {
    background-color: transparentize($telegram_color, .85);

    .button {
      background-color: $telegram_color;

      &:hover {
        background-color: darken($telegram_color, 10%);
      }
    }
  }

  &.vk {
    background-color: transparentize($vk_color, .85);

    .button {
      background-color: $vk_color;

      &:hover {
        background-color: darken($vk_color, 10%);
      }
    }
  }

  &.dzen {
    background-color: transparentize($dzen_color, .85);

    .button {
      background-color: $dzen_color;

      &:hover {
        background-color: darken($dzen_color, 10%);
      }
    }
  }

  &.odnoklassniki {
    background-color: transparentize($odnoklassniki_color, .85);

    .button {
      background-color: $odnoklassniki_color;

      &:hover {
        background-color: darken($odnoklassniki_color, 10%);
      }
    }
  }

  &.pinterest {
    background-color: transparentize($pinterest_color, .85);

    .button {
      background-color: $pinterest_color;

      &:hover {
        background-color: darken($pinterest_color, 10%);
      }
    }
  }

  &.tumblr {
    background-color: transparentize($tumblr_color, .85);

    .button {
      background-color: $tumblr_color;

      &:hover {
        background-color: darken($tumblr_color, 10%);
      }
    }
  }

  &.twitter {
    background-color: transparentize($twitter_color, .85);

    .button {
      background-color: $twitter_color;

      &:hover {
        background-color: darken($twitter_color, 10%);
      }
    }
  }
}

;@include ---ullwtt3r34q.load-css("sass-embedded-legacy-load-done:3863");