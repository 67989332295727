@use "sass:meta" as ---8miszvug6px;@use 'sass:math';

.obj-10 {
  &.margin-bottom-0 {
    margin-bottom: 0;
  }

  &.timeline {
    border-bottom: 1px solid $light-gray;
    padding-bottom: 1rem;
  }

  &__thumb {
    @include obj-thumb;

    line-height: 0;

    &:has(.placeholder) {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__thumb_sq {
    @include obj-thumb(100px, 100px);

    line-height: 0;
  }

  &__thumb_doc {
    aspect-ratio: 99/70;
    line-height: 0;
  }

  &__photo {
    text-align: center;
    max-height: 100px;
    overflow: hidden;
    display: block;
    margin: 0 auto;
    max-width: 100% !important;
    width: auto;

    a {
      vertical-align: top;
    }

    img {
      width: 100%;

      //max-height: 200px;
    }
  }

  &__title {
    font-size: rem-calc(18);
    font-weight: $global-weight-bold;
    margin-bottom: math.div($global-margin, 4);

    .icon-photo {
      margin-left: 4px;
    }

    &.max-width-500 {
      max-width: 500px;
    }
  }

  &__date {
    display: flex;
    gap: 1rem;
    color: $small-font-color;
    font-size: $small-font-size;

    span + span {
      margin-left: $global-margin;
    }
  }

  &__img {
    display: block;
    margin: 0 auto;
    max-width: 100% !important;
    max-height: 500px;
    width: auto;
  }

  &__desc {
    font-size: rem-calc(14);
    margin-bottom: math.div($global-margin, 2);

    &.short {
      max-height: rem-calc(14 * $global-lineheight * 2);
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &.small-max-height-none {
      @include breakpoint(small only) {
        max-height: none;
      }
    }

    .picture_box {
      width: 20rem;

      .picture_thumb {
        max-width: 4rem;
        max-height: 3rem;

        img {
          min-width: 4rem;
        }
      }
    }

    img {
      max-width: 400px;
    }
  }

  &__more {
    color: $small-font-color;
    font-size: rem-calc(13);

    a {
      @include color-mixin($success-color);

      &.unpublish {
        @include color-mixin($light-black);
      }

      &.remove {
        @include color-mixin($alert-color);
      }
    }
  }

  &__user {
    padding-left: rem-calc(16);
    background-image: url('svg/user-name.svg');
    background-repeat: no-repeat;
  }

  .media-object-section {
    &.logo {
      align-items: center;
      display: inherit;

      img {
        height: 100px;
        width: 100px;
      }

      svg {
        width: 100px;
      }
    }

    &.width-50 {
      @include breakpoint(medium) {
        img {
          min-width: 50px;
          max-width: 50px;
        }
      }
    }

    &.size-120x90 {
      align-self: center;
      text-align: center;
      width: 120px;

      a {
        line-height: 0;
        vertical-align: middle;
      }

      img,
      svg {
        max-height: 90px;
        max-width: 120px;
      }
    }

    &.width-196 {
      flex-shrink: 0;
      font-size: rem-calc(18);
      font-weight: $global-weight-bold;
      color: #ff0013;

      @include breakpoint(medium) {
        width: 196px;

        img {
          min-width: 100px;
          max-width: 100px;
        }
      }
    }

    &:only-child {
      display: block;
      padding-right: 0;
      padding-left: 0;
    }
  }
}

;@include ---8miszvug6px.load-css("sass-embedded-legacy-load-done:3841");