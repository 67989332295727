@use "sass:meta" as ---8miszvug6px;@use 'sass:math';

.breadcrumbs {
  background: $alice-blue;
  border-top: 1px solid $medium-gray;
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  padding: 5px 40px 5px $global-padding;
  text-overflow: ellipsis;
  white-space: nowrap;

  @include breakpoint(small only) {
    display: none;
  }

  @media print {
    display: none !important;
  }

  &::after {
    @include css-triangle(6px, $breadcrumbs-item-separator-color, up);

    display: none;
    position: absolute;
    top: 50%;
    cursor: pointer;
    margin-top: -3px;
    right: 1rem;
  }

  li {
    float: none;
    display: inline;

    a:visited {
      color: $breadcrumbs-item-color;
    }
  }

  &.expanded {
    &::after {
      transform: rotate(180deg);
      transform-origin: 50% 50%;
    }
  }

  &.visible {
    &::after {
      display: block;
    }
  }
}

;@include ---8miszvug6px.load-css("sass-embedded-legacy-load-done:3822");