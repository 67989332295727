@use "sass:meta" as ---ullwtt3r34q;#collapse {
  &.rubrics {
    @include breakpoint(small only) {
      background-color: #f5f5f5;
      border-radius: 15px 15px 0 0;
    }

    &_catalog {
      @include breakpoint(small only) {
        background-color: #f5f5f5;
      }

      border-radius: 0 0 15px 15px;
    }
  }

  &_button.rubrics {
    &_buttons {
      @include breakpoint(small only) {
        margin: 0 0 20px;
        background-color: #5073af;
        border-radius: $global-radius;
        padding: 10px 0 10px 10px;
        color: #fff;
        font-weight: $global-weight-bold;

        &::after {
          border-color: #fff transparent transparent;
        }
      }

      @include breakpoint(medium) {
        display: none;
      }

      &.catalog {
        margin: 0;
      }
    }
  }
}

.rubrics {
  &_wrapper {
    @include breakpoint(medium) {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
  }
}

.m-menu {
  max-height: 100%;
  background-color: #4d59a1;

  &__icon-close {
    margin: 12px 16px 0 0;
    outline: none !important;
    line-height: 1;
    position: static;
    font-size: rem-calc(14);
  }

  &_bg-1 {
    background-color: #4d59a1;
  }

  &_bg-2 {
    background-color: #3d4886;
  }

  &_border {
    border-top: 1px solid #a6a6a7;
    border-bottom: 1px solid #a6a6a7;
  }

  &-padding-top-0 {
    padding-top: 0 !important;
  }

  &-l {
    margin: 0 auto;
    padding: 10px 0;
    position: relative;

    &-clear {
      clear: both;
    }

    li {
      display: block;
      height: auto;
      padding: 0;
      box-sizing: border-box;
      list-style: none;
      line-height: initial;
      margin: 0 0 0 20px;

      &:hover,
      &.selected {
        background-color: #66698a;
      }

      &.selected a {
        color: #fecc00 !important;
        border-left: 3px solid #fecc00;
      }

      &:last-child {
        border-bottom: none;
      }

      a {
        outline: none;
        display: block;
        padding: 7px 13px;
        color: $white !important;
        border-left: 3px solid $white;

        &.m-ava {
          display: inline-block;
        }

        &.m-badge {
          display: inline-block;
          float: right;
          height: 100%;
          background-color: #c9c9c9;
          color: $white;
          font-weight: $global-weight-normal;
        }

        &.arlight {
          color: #ff0014 !important;
          background-color: #fff;
          margin-top: 20px;
          border-left: 3px solid;
        }

        &.foton {
          color: $tangerine-yellow !important;
        }
      }
    }

    li.bottom-link {
      display: block;
      margin: 2px 0 0 17px;
      font-size: small;

      a {
        display: inline-block;
        border-left: none;
        padding: 5px 3px;
      }

      &:hover,
      &.selected {
        background-color: #4d59a1;
      }
    }

    &__icons {
      padding: 10px 0 10px 7px !important;

      li {
        display: inline-block;
        margin: 2px 0 0 11px;
        font-size: small;

        a {
          border-left: none;
          padding: 5px 3px;
        }

        &:hover,
        &.selected {
          background-color: #4d59a1;
        }
      }
    }

    ul {
      margin-left: 3px;
    }

    .m-login {
      height: 54px;

      i {
        display: inline-block;
        float: left;
        vertical-align: top;
        margin: 0 11px 0 15px !important;
      }

      a {
        display: inline-block;
        vertical-align: top;
        margin-top: 16px;
        color: $white !important;
      }

      a + a::before {
        content: '/';
        margin-right: .25rem;
      }
    }

    .m-registered {
      height: 54px;

      &__ava {
        display: inline-block;
        vertical-align: top;
        position: relative;

        img {
          width: 54px;
          height: 54px;
          border: 1px solid $white;
          border-radius: 50%;
          margin: 0 10px 0 15px;
        }

        &-w {
          height: 54px;
          display: inline;
          float: left;
        }

        &-m::after {
          content: '';
          position: absolute;
          display: inline-block;
          width: 12px;
          height: 12px;
          top: 2px;
          right: 11px;
          border-radius: $global-radius;
          background-color: $alert-color;
        }
      }

      &__info {
        display: inline-block;
        vertical-align: center;
        padding-top: 6px;
        max-width: 162px;

        span {
          display: block;
          margin: 0;
          padding-left: 3px;
          line-height: 1.3;

          a {
            box-sizing: border-box;
            border-left: none;
            font-size: smaller;
            color: $white;
          }
        }

        span.name {
          white-space: nowrap;
          font-weight: $global-weight-bold;
          overflow: hidden;
          color: $white;
          width: 166px;
          text-overflow: ellipsis;
        }
      }
    }

    .message-count {
      background-color: $white;
      border-radius: $global-radius;
      color: #4d59a1;
      font-weight: $global-weight-bold;
      padding: 0 5px;
      font-size: small;
    }
  }

  &-panel {
    display: none;
    margin-top: 10px;
    margin-bottom: 0;

    li {
      display: block;
      margin: 0 0 0 $global-margin;
      font-size: small;
      background-color: #3d4886;

      &:hover,
      &.selected {
        background-color: #3d4886;
      }

      a {
        display: inline-block;
        padding: 8px 3px;
        border-left: none !important;

        &:hover,
        &.selected {
          background-color: #3d4886;
          border-left: none !important;
          color: #fedd67 !important;
        }
      }

      &.bold {
        font-weight: $global-weight-bold;
      }
    }
  }

  &-5 {
    @include small-mobile;

    @include breakpoint(medium) {
      display: flex;
      flex-wrap: wrap;
      font-size: rem-calc(14);
      background-color: $alice-blue;
      border-radius: $global-radius;
      padding: 10px 10px 20px;

      ul {
        @include breakpoint(medium) {
          line-height: 1.3;
        }

        li {
          @include breakpoint(medium) {
            padding: 8px 10px 0;
            align-items: center;
          }
        }
      }

      &__title {
        display: none;
      }
    }

    &.forum {
      margin-bottom: 0;
    }
  }
}

;@include ---ullwtt3r34q.load-css("sass-embedded-legacy-load-done:3837");