@use "sass:meta" as ---rj7841chyf;@use 'sass:math';

.documents {
  margin-bottom: $global-margin;
  display: block;

  &-item {
    padding: math.div($global-padding, 2);
    margin: math.div($global-margin, 2);
    position: relative;

    &:hover {
      background-color: rgb(221 238 247 / 35%);
    }

    &__icon {
      position: absolute;
      width: 24px;
      height: 24px;
      padding: 0;
      margin: 0;
      line-height: 1;
      top: 17px;
      left: 3px;
    }

    &__info {
      margin-left: 34px;
      line-height: 1;
      font-size: rem-calc(13);
    }

    .documents-item__title {
      vertical-align: top;
      margin-top: 0;
      margin-left: 33px;
      display: inline-block;

      @include color-mixin($light-black);
    }

    &__date {
      display: inline-block;
      margin: 0 5px;
    }

    &__view {
      display: inline-block;
      margin: 0 10px;
    }

    &__time {
      padding-top: 4px;
      color: $dark-gray;
    }

    &__download {
      display: inline-block;
      color: $dark-gray;
      margin: 0 5px 0 0;
      line-height: 1.2;

      //text-decoration: underline;
      text-decoration-style: dotted;

      &:hover,
      &:visited {
        color: $dark-gray;
      }
    }
  }

  &__files {
    margin: 2rem 0;

    h3 {
      font-weight: $global-weight-bold;
      font-size: rem-calc(18);
    }

    &-item {
      display: flex;
      align-items: center;

      &:nth-child(n+3) {
        margin-top: 18px;
      }

      .icon {
        margin-right: 10px;
      }

      .property {
        color: #8e8e8e;
        font-size: rem-calc(13);
      }
    }
  }
}

;@include ---rj7841chyf.load-css("sass-embedded-legacy-load-done:3830");