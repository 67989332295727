@use "sass:meta" as ---rj7841chyf;.offer-tizer {
  @include flex-grid-row;

  border-bottom: solid 1px $light-gray;
  padding-bottom: $global-padding;
  padding-top: $global-padding;
  margin-bottom: $global-margin;
  margin-top: $global-margin;

  @include breakpoint(small only) {
    flex-direction: column;
  }

  &__img {
    @include flex-grid-column(20%);

    align-self: center;
    text-align: center;

    @include breakpoint(small only) {
      max-width: 80%;
    }

    a {
      display: flex;
      justify-content: center;
    }
  }

  &__wrap {
    @include flex-grid-column;

    overflow: hidden;
    text-overflow: ellipsis;

    @include breakpoint(small only) {
      flex-basis: 100%;
    }
  }

  &__price {
    @include flex-grid-column(3);

    font-size: rem-calc(18);
    overflow: hidden;
    text-overflow: ellipsis;

    @include breakpoint(small only) {
      @include flex-grid-column(12);

      font-size: rem-calc(22);
    }

    &.align-center {
      align-self: center;
    }

    .in-stock {
      color: $buy;
      font-size: $global-font-size;
      margin-top: $global-margin;
    }

    .pre-order {
      color: $vacancy;
      font-size: $global-font-size;
      margin-top: $global-margin;
    }

    .action {
      margin-top: 1rem;
      display: flex;
      flex-direction: column;
      gap: 1rem;

      @include breakpoint(medium) {
        margin-top: 2rem;
      }
    }

    .date {
      color: $dark-gray;
      font-size: rem-calc(13);
      margin-top: $global-margin;
    }
  }

  &__title {
    font-size: rem-calc(18);
    margin-bottom: .5rem;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    display: -webkit-box; /* stylelint-disable-line */
    overflow: hidden;
  }

  &__desc {
    display: flex;
    flex-direction: column;
    font-size: rem-calc(13);

    span {
      color: $dark-gray;
    }
  }

  &__more {
    color: $light-black;
    font-size: rem-calc(14);
    margin-top: $global-margin;

    a {
      color: $buy;
    }
  }

  &__user {
    padding-left: $global-padding;
    background-image: url('../common/svg/user-name.svg');
    background-repeat: no-repeat;
  }

  &__button {
    @include button($expand: true, $background: $alice-blue, $background-hover: auto, $color: $black);

    margin-bottom: 0;

    &.order-form {
      @include button-style($background: $tangerine-yellow, $background-hover: auto, $color: $black);
    }

    &.on-site {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: .3rem;

      &::after {
        content: '';
        display: inline-block;
        background-image: url('../common/svg/on-site.svg');
        background-size: contain;
        background-repeat: no-repeat;
        width: 12px;
        height: 12px;
        opacity: .5;
      }
    }
  }
}

;@include ---rj7841chyf.load-css("sass-embedded-legacy-load-done:3895");