@use "sass:meta" as ---8miszvug6px;.icon {
  &-delete { // заменен на текст
    width: 12px;
    height: 12px;
    background-image: url('./svg/forum-delete-inline.svg');
  }

  &-edit { // заменен на текст
    width: 12px;
    height: 12px;
    background-image: url('./svg/forum-edit-inline.svg');
  }

  &-quote { // заменен на текст
    width: 12px;
    height: 11px;
    background-image: url('./svg/forum-quote-inline.svg');
  }
}

;@include ---8miszvug6px.load-css("sass-embedded-legacy-load-done:3888");