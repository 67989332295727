@use "sass:meta" as ---3m2zn08l3uw;.clearable-image {
  input[type='file'] {
    @include element-invisible;
  }

  &__preview {
    float: left;
    margin-bottom: $global-margin;
    margin-right: $global-margin;
  }

  &__file {
    display: inline-block;
    vertical-align: middle;
    margin: $button-margin;
    padding: $button-padding;
    line-height: 1;
  }
}

;@include ---3m2zn08l3uw.load-css("sass-embedded-legacy-load-done:3869");