@use "sass:meta" as ---ullwtt3r34q;.menu-header {
  @include show-for(large);

  display: flex;
  font-size: rem-calc(13);
  font-weight: $global-weight-bolder;
  justify-content: space-between;
  overflow: hidden;
  text-transform: uppercase;
  gap: .5rem;

  .wrapper {
    display: flex;
    height: rem-calc(40);
    background-color: map-get($foundation-palette, primary);
    align-items: stretch;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 11px;
    border-radius: $global-radius;
    flex-grow: 1;
    justify-content: space-between;

    @media print {
      background-color: $medium-gray !important;
    }

    &.arlight {
      background-color: #ff0014;
      font-weight: $global-weight-normal;
      flex-grow: 0;

      &:hover {
        background-color: #da2230;
      }

      a {
        color: $white;
      }
    }

    .branding-enabled & {
      &:first-child {
        border-radius: 0 $global-radius $global-radius 0;
      }

      &:last-child {
        border-radius: $global-radius 0 0 $global-radius;
      }
    }
  }

  div {
    a {
      color: $white;

      &:visited {
        color: $white;
      }

      &:hover {
        color: get-color(tangerine-yellow);
      }
    }

    &.foton {
      a {
        color: $tangerine-yellow;
      }
    }

    &.selected {
      border-bottom: 3px solid get-color(tangerine-yellow);

      a {
        color: get-color(tangerine-yellow);
      }
    }
  }
}

;@include ---ullwtt3r34q.load-css("sass-embedded-legacy-load-done:3839");