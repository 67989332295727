@use "sass:meta" as ---3m2zn08l3uw;@use 'sass:math';

.news-popular {
  @include grid-column-margin;

  h3 {
    font-weight: $global-weight-bold;
  }

  .item {
    border-bottom: solid 1px $medium-gray;
    margin-bottom: $global-margin;
    padding-bottom: $global-margin;

    &:last-child {
      border-bottom: none;
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }

  .title {
    font-weight: $global-weight-bolder;
    font-size: rem-calc(15);
    margin-bottom: math.div($global-margin, 2);
  }

  .photo {
    align-items: center;
    aspect-ratio: 3/2;
    display: flex;
    justify-content: center;
    margin-bottom: math.div($global-margin, 2);
  }

  .pub-date {
    font-size: rem-calc(12);
  }
}

;@include ---3m2zn08l3uw.load-css("sass-embedded-legacy-load-done:3898");