@use "sass:meta" as ---3m2zn08l3uw;footer,
.footer {
  background-color: $light-gray;
  background-image: linear-gradient(to bottom, #f4f4f4, $white);
  border-top: 1px solid $light-gray;
  box-sizing: border-box;
  color: $dark-gray;
  font-size: rem-calc(14);
  margin: 0 auto;

  @include breakpoint(medium) {
    border: 1px solid rgb(0 0 0 / 5%);
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 5%);
  }

  .wrap {
    @include xy-cell-gutters($gutter-type: padding, $gutter-position: top bottom left right, $breakpoint: 'auto');
  }

  &-branding {
    position: relative;
    z-index: 3;

    @include breakpoint(xlarge) {
      width: $global-width;
    }
  }

  a,
  a:visited {
    color: $dark-gray;
  }

  a:hover {
    color: $light-black;
  }

  .column-title {
    border-bottom: 1px solid $medium-gray;
    font-weight: $global-weight-bold;
    margin-bottom: .5rem;
    overflow: hidden;
    padding: 0 0 .5rem;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .copyright {
    border-top: 1px solid #cacaca;
    font-size: rem-calc(12);
    padding-top: $global-padding;
    text-align: center;

    p {
      margin-bottom: .5rem;
    }
  }

  .counters {
    margin-top: $global-margin;
    justify-content: center;

    @media print {
      display: none !important;
    }

    .cell {
      opacity: .25;
      width: auto;

      &:hover {
        opacity: .7;
      }
    }
  }
}

;@include ---3m2zn08l3uw.load-css("sass-embedded-legacy-load-done:3833");