@use "sass:meta" as ---8miszvug6px;.complaint {
  @include breakpoint(small only) {
    padding-top: 3.5rem;
  }

  form {
    label {
      font-size: $global-font-size;
    }

    select {
      width: 100%;
    }

    textarea {
      height: 10rem;
    }

    ul#id_reason {
      list-style: none;
      margin-left: 0;
      margin-bottom: 0;
    }
  }
}

;@include ---8miszvug6px.load-css("sass-embedded-legacy-load-done:3699");