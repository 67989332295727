@use "sass:meta" as ---ullwtt3r34q;@use 'sass:math';

.grid-announce {
  @include xy-grid;
  @include xy-gutters($gutter-position: top bottom);
  @include show-for(medium);

  .cell-announce {
    @include xy-cell(math.div(1, 3), $breakpoint: 'auto');
  }

  a {
    color: $light-black;

    &:visited {
      color: $light-black;
    }

    &:hover {
      color: $primary-color;
    }
  }

  .media-object {
    height: rem-calc(60);
    margin-bottom: 0;
    overflow: hidden;

    @include breakpoint(medium only) {
      max-height: rem-calc(64);
    }

    &-section {
      align-self: center;
      font-size: rem-calc(14);
      line-height: 1.4;
      word-break: break-word;

      &.logo {
        display: inline-flex;
        line-height: 0;
        height: 60px;
        width: 90px;
      }

      &.photo {
        @include xy-gutters($gutter-position: right);

        line-height: 0;
        height: 60px;
        width: 90px;
      }

      &.title {
        align-self: normal;
        max-height: 60px;

        @include breakpoint(xlarge) {
          align-self: center;
        }
      }

      &:only-child {
        padding-right: 0;
        padding-left: 0;
      }

      img,
      svg {
        border-radius: $global-radius;
        max-width: 90px;
        max-height: 60px;
      }
    }
  }
}

;@include ---ullwtt3r34q.load-css("sass-embedded-legacy-load-done:3818");