@use "sass:meta" as ---8miszvug6px;@use 'sass:math';

.address-1 {
  font-style: normal;
  font-size: rem-calc(14);
  margin-bottom: $global-margin;

  @include breakpoint(medium) {
    border: 1px solid darken(get-color(grayish-blue), 5%);
    background-color: scale_color(get-color(grayish-blue), $lightness: 85%);
    border-radius: $global-radius;
    padding: $global-padding;
  }

  &__text {
    margin-bottom: math.div($global-margin, 2);
    color: $light-black;
    font-size: rem-calc(14);
  }

  &__title {
    font-weight: $global-weight-bold;
  }

  &__link {
    font-size: rem-calc(14);
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: math.div($global-margin, 2);

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__icon {
    display: inline-block;
    padding: 0;

    &:not(:last-child) {
      margin: 0 3px 0 0;
    }
  }

  &__h {
    font-size: rem-calc(14);
    font-weight: $global-weight-bold;
  }

  @include small-l;

  @include breakpoint(small only) {
    div:first-child {
      border-top: 1px solid $medium-gray;
    }
  }
}

.address-brand {
  border-radius: $global-radius;
  padding: 1rem;
  background-color: #f1f6f8;
  border: 1px solid #8ebacb;

  @include breakpoint(small only) {
    margin-top: 2rem;
  }

  @include breakpoint(medium) {
    margin-bottom: 1rem;
  }

  &__h {
    font-size: rem-calc(15);
    font-weight: $global-weight-bold;
    margin-bottom: .5rem;
  }

  &__link {
    font-size: rem-calc(14);
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: math.div($global-margin, 2);

    &:last-child {
      margin-bottom: 0;
    }
  }
}

;@include ---8miszvug6px.load-css("sass-embedded-legacy-load-done:3815");