@use "sass:meta" as ---3m2zn08l3uw;.counter {
  $height: ($input-font-size * unitless-calc($input-line-height)) + (get-side($input-padding, 'top') + get-side($input-padding, 'bottom')) - rem-calc(1);

  display: flex;
  flex-direction: row;
  width: 120px;
  height: $height;

  &__btn {
    align-items: center;
    background-color: transparent;
    background-position-y: 50%;
    background-repeat: no-repeat;
    background-size: 24px 24px;

    //border-color: rgb(55, 60, 62);
    border-radius: 20px;
    border: $input-border;
    cursor: pointer;
    display: flex;
    justify-content: center;
    margin: 0;

    //outline-color: initial !important;
    outline: 0 !important;
    padding: 0;
    width: 30px;

    &--left {
      border-right: none;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      background-image: url('svg/counter-left-inline.svg');
      background-position-x: 3px;

      &:hover {
        background-image: url('svg/counter-left-hover-inline.svg');
      }

      &:disabled {
        background-image: url('svg/counter-left-disabled-inline.svg');
      }
    }

    &--right {
      border-left: none;
      border-left-color: initial;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      background-image: url('svg/counter-right-inline.svg');
      background-position-x: -2px;

      &:hover {
        background-image: url('svg/counter-right-hover-inline.svg');
      }

      &:disabled {
        background-image: url('svg/counter-right-disabled-inline.svg');
      }
    }
  }

  &__amount {
    border-radius: 0;
    border-left: none;
    border-left-color: initial;
    border-right: none;
    border-right-color: initial;
    margin-bottom: 0;
    text-align: center;
    width: 60px;
    appearance: textfield;

    &:focus {
      border: $input-border;
      border-left: none;
      border-left-color: initial;
      border-right: none;
      border-right-color: initial;
      box-shadow: none;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      /* display: none; <- Crashes Chrome on hover */
      margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
      appearance: none;
    }
  }
}

;@include ---3m2zn08l3uw.load-css("sass-embedded-legacy-load-done:3871");