@use "sass:meta" as ---rj7841chyf;.certificats {
  margin: 20px 0;

  &-item {
    border: 1px solid rgb(217 216 216);
    border-bottom: none;
    height: 161px;
    margin-top: 10px;
    padding: 10px 15px;
    position: relative;

    &:last-child {
      border-bottom: 1px solid rgb(217 216 216);
    }

    &__trumb {
      width: 100px;

      img {
        aspect-ratio: 99/70;
        margin: 0;
      }
    }

    &__title {
      color: rgb(41 41 41);
      font-weight: $global-weight-bolder;
      line-height: 1.3;
      margin-top: 10px;

      @include breakpoint(medium) {
        line-height: 20px;
        height: 40px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box; /* stylelint-disable-line */
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;  /* stylelint-disable-line property-no-vendor-prefix */
      }
    }

    &__desc {
      margin-top: 10px;
      font-size: rem-calc(12);
      color: rgb(41 41 41);
      line-height: 14px;
      height: 42px;
      overflow: hidden;
    }

    &__dates {
      bottom: 14px;
      color: rgb(117 117 117);
      display: block;
      font-size: rem-calc(14);
      left: 122px;
      position: absolute;

      @include breakpoint(large) {
        left: 130px;
      }

      span {
        display: inline-block;
      }
    }
  }
}

;@include ---rj7841chyf.load-css("sass-embedded-legacy-load-done:3823");