@use "sass:meta" as ---3m2zn08l3uw;.share-4 {
  @media print {
    display: none !important;
  }

  .social-likes {
    font-size: rem-calc(13);
    margin-bottom: $global-margin;

    li {
      margin-right: 0;
    }
  }

  .input-group {
    margin-bottom: 0;
  }

  @include small-padding;
}

.share-dropdown {
  width: 45rem;
}

;@include ---3m2zn08l3uw.load-css("sass-embedded-legacy-load-done:3858");