@use "sass:meta" as ---8miszvug6px;.slider-1 {
  @include breakpoint(medium) {
    margin-bottom: $global-margin;
  }

  &__wrap {
    &::after {
      @include small-h;

      @include breakpoint(small only) {
        content: '';
        position: relative;
        height: 10px;
        padding: 0 !important;
      }
    }

    @include breakpoint(medium only) {
      margin-top: 40px;
    }
  }

  & > div:not(:first-child) {
    display: none;
  }

  .slick-slide {
    position: relative;
  }

  .slick-dots {
    position: absolute;
    padding: 0;
    height: 20px;
    right: 0;
    top: -30px;
    margin: 0;

    li {
      float: left;
      font-size: 0;
      line-height: 0;
      list-style: none;
      padding: 0;
    }

    li button {
      display: block;
      width: 24px;
      height: 24px;
      background-color: scale_color(get-color(tangerine-yellow), $lightness: 75%);
      font-size: 0;
      line-height: 0;
      text-decoration: none;
      margin-right: 3px;
      outline: none;
    }

    li button:hover {
      background-color: #fc3;
    }

    li.slick-active button {
      background-color: #fc3;
    }
  }

  &__img {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    display: inline-block;
    width: 100%;
    height: 480px;

    @include breakpoint(small only) {
      height: 300px;
    }

    @include breakpoint(medium) {
      border: solid 1px $light-gray;
      border-radius: $global-radius;
    }
  }

  &__desc {
    overflow: hidden;

    @include breakpoint(medium) {
      position: absolute;
      bottom: 20px;
      left: 20px;
      margin: 0;
      padding: $global-padding;
      color: $white;
      background-color: rgb(41 41 41 / 67%);
      border-radius: $global-radius;
      width: calc(100% - 40px);
    }
  }

  &__title {
    font-size: rem-calc(18);
    margin: 0 0 6px;

    a {
      display: block;
      color: $black;
      font-weight: $global-weight-bold;
      line-height: 1.2;

      @include breakpoint(medium) {
        font-weight: $global-weight-normal;
        color: map-get($foundation-palette, warning);
        text-decoration: underline;
      }
    }
  }

  &__text {
    font-size: rem-calc(14);
    margin-bottom: 5px;

    a {
      color: $white;

      &:visited {
        color: $white;
      }
    }
  }

  &__more {
    margin-bottom: 5px;

    a {
      text-decoration: underline;
      color: map-get($foundation-palette, success);
    }
  }
}

;@include ---8miszvug6px.load-css("sass-embedded-legacy-load-done:3859");