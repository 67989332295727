@use "sass:meta" as ---8miszvug6px;@use 'sass:math';

.tabs-1 {
  padding: 0;
  margin: 0 0 -2px;

  @include clearfix;

  &__wrap {
    margin: 0 0 $global-margin;
    padding: 0 $global-padding;
    box-sizing: border-box;
    border-bottom: solid 1px $medium-gray;

    &.brands {
      @include breakpoint(small only) {
        padding: 0;
        margin: 1rem 0;
        border: none;
      }
    }

    h1 + & {
      margin-top: $global-margin;
    }
  }

  li {
    display: inline-block;
    position: relative;
    height: 2.5rem;
    font-size: rem-calc(14);

    &.tabs-1__right {
      @include breakpoint(medium) {
        float: right;
      }
    }

    a {
      display: inline-block;
      padding: math.div($global-padding, 2) $global-padding 0;

      @include color-mixin($primary-color);
    }

    &.active,
    &.is-active {
      margin-bottom: 1px;
      padding: math.div($global-padding, 2) $global-padding 0;
      font-weight: $global-weight-bold;
      border: solid 1px $medium-gray;
      border-bottom: solid 1px white;
      border-radius: $global-radius $global-radius 0 0;
      background-color: #fff;

      a {
        padding: 0;

        @include color-mixin($light-black);
      }

      span {
        margin-left: rem-calc(4);
        padding-right: 0;
      }
    }

    &.collapse {
      position: relative;
      padding-top: 6px;

      &:hover ul {
        display: block;
        position: absolute;
        z-index: 1000;
      }

      ul {
        display: none;
        min-width: 190px;
        width: auto;
        margin: 0;
        background: $white;
        padding: 10px;
        box-sizing: border-box;
        border: 1px solid $medium-gray;
        border-radius: $global-radius;
        right: 0;

        li {
          display: block;

          &.active,
          &.is-active {
            font-weight: $global-weight-normal;
            border: none;
          }
        }

        a {
          white-space: nowrap;
        }
      }
    }

    span {
      margin-left: -6px;
      padding-right: 10px;
      font-size: rem-calc(11);
      font-weight: $global-weight-normal;
      color: $dark-gray;

      &.tabs-1__more {
        display: inline-block;
        box-sizing: border-box;
        padding-left: 5px;
        font-size: $global-font-size;
        cursor: pointer;
        color: $primary-color;
      }
    }
  }
}

.tabs-panel {
  display: none;

  &.is-active,
  &[aria-hidden='false'] {
    display: block;
  }
}

;@include ---8miszvug6px.load-css("sass-embedded-legacy-load-done:3864");