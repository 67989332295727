@use "sass:meta" as ---rj7841chyf;@use 'sass:math';

.tabs-2 {
  @include clearfix;

  margin: 0;
  list-style: none;

  &__wrap {
    margin: $global-margin 0;
  }

  li {
    display: inline-block;
    padding: rem-calc(0 math.div($global-padding, 2));

    &.text {
      padding-left: 0;
    }

    &.selected {
      font-weight: $global-weight-bold;
      background: scale_color(get-color(tangerine-yellow), $lightness: 75%);
      border-radius: $global-radius;
    }
  }

  a {
    font-weight: $global-weight-bold;

    &:visited {
      color: $primary-color;
    }
  }
}

;@include ---rj7841chyf.load-css("sass-embedded-legacy-load-done:3865");