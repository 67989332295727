@use "sass:meta" as ---rj7841chyf;.sub-text {
  padding: 0;
  color: $dark-gray;
  font-size: rem-calc(11);

  a {
    color: $dark-gray;
  }
}

;@include ---rj7841chyf.load-css("sass-embedded-legacy-load-done:3862");