@use "sass:meta" as ---rj7841chyf;.address-2 {
  line-height: rem-calc(20);
  font-style: normal;

  @include breakpoint(small only) {
    margin-top: 20px;
  }

  & > div {
    margin-bottom: 7px;
  }

  b {
    display: inline-block;
    width: 15%;

    @include breakpoint(small only) {
      width: 100%;
    }
  }

  @include small-padding;
}

;@include ---rj7841chyf.load-css("sass-embedded-legacy-load-done:3816");