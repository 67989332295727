@use "sass:meta" as ---8miszvug6px;.obj-15 {
  @include grid-column-margin;

  padding: $global-padding;
  font-size: rem-calc(13);
  width: 100%;

  @include breakpoint(medium) {
    border: 1px solid $medium-gray;
    border-radius: $global-radius;
  }

  &__tizer {
    margin-bottom: $global-margin;

    @include breakpoint(small only) {
      margin-top: $global-margin;
      padding: 0 $global-padding !important;
    }
  }
}

;@include ---8miszvug6px.load-css("sass-embedded-legacy-load-done:3842");