@use "sass:meta" as ---ullwtt3r34q;.obj-16 {
  @include grid-column-margin;

  padding: $global-padding;
  position: relative;
  width: 100%;

  @include breakpoint(medium) {
    padding: $global-padding;
    border: 1px solid $medium-gray;
    border-radius: $global-radius;
    background-color: $alice-blue;
  }

  .h3 {
    margin-bottom: $global-margin;
  }

  &__ava {
    float: left;
    display: block;
    width: 44px;
    height: 44px;
    position: relative;
    text-align: left;

    img {
      border-radius: 50%;
      border: 1px solid $medium-gray;
    }
  }

  &__contact {
    position: relative;
    display: block;
    margin-left: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
    min-height: 50px;

    &-name {
      width: 100%;
      font-weight: $global-weight-bolder;
      white-space: nowrap;
      overflow: hidden;
      cursor: pointer;
      color: $primary-color;

      &:hover {
        text-decoration: underline;
      }
    }

    &-appointment {
      color: $dark-gray;
      font-size: rem-calc(12);
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__info {
    margin: $global-margin 0;
    font-size: rem-calc(15);
    list-style: none;

    @include breakpoint(small only) {
      padding: 0 $global-padding !important;
    }

    li {
      font-weight: $global-weight-bold;

      a,
      a:visited {
        color: $black;
      }

      span {
        color: $light-black;
        font-weight: $global-weight-normal;
      }

      @include breakpoint(small only) {
        line-height: 24px;
        padding: 0 10px;
      }
    }
  }
}

;@include ---ullwtt3r34q.load-css("sass-embedded-legacy-load-done:3843");