@use "sass:meta" as ---rj7841chyf;.messages {
  bottom: 30px;
  height: auto;
  position: fixed;
  right: 30px;
  width: 500px;
  z-index: 500;

  .callout {
    h5 {
      font-weight: $global-weight-bolder;
    }

    &.success {
      border: 2px solid rgba(map_get($foundation-palette, success), .25);

      .close-button {
        color: map_get($foundation-palette, success);
      }
    }

    &.warning {
      border: 2px solid rgba(map_get($foundation-palette, warning), .25);

      .close-button {
        color: map_get($foundation-palette, warning);
      }
    }

    &.error {
      @include callout-style(map_get($foundation-palette, alert));

      border: 2px solid rgba(map_get($foundation-palette, alert), .25);
      color: map_get($foundation-palette, alert);

      .close-button {
        color: map_get($foundation-palette, alert);
      }
    }
  }
}

.is-agree {
  color: $dark-gray;
  font-size: rem-calc(11);
  margin-top: .5rem;

  a {
    text-decoration: underline;
  }
}

;@include ---rj7841chyf.load-css("sass-embedded-legacy-load-done:3840");