@use "sass:meta" as ---8miszvug6px;&.company-recent {
  h3 {
    font-weight: $global-weight-bold;
  }

  svg {
    height: 40px;
    width: 60px;
  }

  .title {
    font-weight: $global-weight-bolder;
    margin-bottom: .5rem;
  }

  .geo {
    font-size: $small-font-size;
    color: $small-font-color;
  }
}

;@include ---8miszvug6px.load-css("sass-embedded-legacy-load-done:3906");