@use "sass:meta" as ---ullwtt3r34q;.help-parts {
  .part {
    border-bottom: 1px solid $medium-gray;
    padding: $global-padding 0;

    a {
      font-size: rem-calc(20);
      font-weight: $global-weight-bolder;
    }
  }
}

;@include ---ullwtt3r34q.load-css("sass-embedded-legacy-load-done:3892");