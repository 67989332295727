@use "sass:meta" as ---ullwtt3r34q;.offer-order {
  @include reveal-modal-width(60%);

  @include breakpoint(small only) {
    padding: 2rem 1.5rem;
  }

  @include breakpoint(large down) {
    @include reveal-modal-fullscreen;
  }

  padding: 2rem;

  h2 {
    font-weight: $global-weight-bold;
    margin-bottom: $global-margin;
  }

  h3 {
    margin-bottom: $global-margin;
  }

  button, input, textarea {
    margin-bottom: 0;
  }

  .errorlist {
    margin: 0;

    li {
      margin-top: .5rem;
      padding: 0;
    }
  }

  #id_client,
  #id_delivery {
    display: flex;
    gap: $global-margin;

    @include breakpoint(small only) {
      flex-direction: column;
      gap: .5rem;
    }
  }

  .offer-item {
    &__wrap {
      display: flex;
      gap: 1rem;
      border-top: solid 1px $light-gray;
      padding-top: $global-padding;

      @include breakpoint(medium) {
        align-items: center;;
      }
    }

    &__picture {
      width: 90px;
      flex-shrink: 0;
    }

    &__info {
      display: flex;

      @include breakpoint(small only) {
        flex-direction: column;
        gap: 1rem;
      }

      @include breakpoint(medium) {
        align-items: center;
        gap: 1rem;
        width: 100%;
        justify-content: space-between;
      }

      .title {
        font-size: rem-calc(16);

        @include breakpoint(small only) {
          font-size: rem-calc(14);
        }

        @include breakpoint(medium) {
          flex-basis: 270px;
          flex-shrink: 0;
        }
      }

      .price {
        flex-shrink: 0;
        text-wrap: nowrap;

        @include breakpoint(small only) {
          font-size: rem-calc(16);
          font-weight: $global-weight-bold;
        }

        @include breakpoint(medium) {
          flex-basis: 75px;
        }
      }

      .quantity {
        flex-shrink: 0;

        @include breakpoint(medium) {
          flex-basis: 15%;

          //input {
          //  width: 50%;
          //}
        }

        @include breakpoint(small only) {
          width: 50%;
        }

      }

      .total {
        @include breakpoint(small only) {
          display: none;
        }

        input {
          font-size: rem-calc(16);
          font-weight: $global-weight-bold;
        }
      }
    }
  }

  .border-bottom {
    border-bottom: solid 1px $light-gray;
    padding-bottom: $global-padding;
  }

  .grid-order {
    @include xy-grid;

    & > .cell {
      @include xy-cell-gutters($gutter-position: top bottom, $breakpoint: 'auto');
    }

    &.info {
      font-size: rem-calc(13);
      color: $dark-gray;
    }

    &.actions {
      @include breakpoint(small only) {
        button {
          @include button-expand;
        }
      }
    }
  }
}

;@include ---ullwtt3r34q.load-css("sass-embedded-legacy-load-done:3894");