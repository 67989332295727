@use "sass:meta" as ---3m2zn08l3uw;.company-status {
  display: block;
  position: absolute;
  width: 350px;
  padding: 10px;
  background-color: rgb(255 253 241);
  box-shadow: rgb(232 232 232) 0 0 0 1px;
  font-size: rem-calc(13);
  line-height: 16px;
  z-index: 4;
  font-weight: $global-weight-normal;
  color: $black;

  a.company-status__ref {
    color: #390 !important;
  }
}

;@include ---3m2zn08l3uw.load-css("sass-embedded-legacy-load-done:3882");