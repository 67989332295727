@use "sass:meta" as ---ullwtt3r34q;.slider-2 {
  min-height: 277px;

  @include breakpoint(large) {
    min-height: 328px;
  }

  & > div:not(:first-child) {
    display: none;
  }

  &__title {
    display: block;
    padding: 10px 1rem;
    color: $black;
    font-weight: $global-weight-bold;
    line-height: 1.2;

    a {
      color: $black;
    }

    @include breakpoint(medium) {
      margin: .5rem 0;
      padding: 0;
      overflow: hidden;
    }
  }
}

;@include ---ullwtt3r34q.load-css("sass-embedded-legacy-load-done:3860");