@use "sass:meta" as ---3m2zn08l3uw;@use 'sass:math';

.content {
  margin-bottom: 2rem;
  word-wrap: break-word;

  @include breakpoint(small only) {
    margin-top: $global-margin;
  }

  a {
    &[rel='lightbox'] {
      outline: none !important;
    }
  }

  .anchor-color-visited {
    a:visited {
      color: grayscale($anchor-color);
    }
  }

  img {
    vertical-align: inherit;

    &.avatar {
      vertical-align: top;
    }

    &.picture-center {
      @include breakpoint(medium) {
        margin: auto;
      }
    }

    &[align='left'] {
      margin-right: 1rem;

      @include breakpoint(small only) {
        float: none !important;
        margin-right: 0;
        width: 100%;
      }
    }

    &[align='right'] {
      margin-left: 1rem;

      @include breakpoint(small only) {
        float: none !important;
        margin-left: 0;
        width: 100%;
      }
    }
  }

  .limg,
  .limg:only-child {
    text-align: center;

    table {
      width: 100%;
    }

    @include breakpoint(medium) {
      float: left;
      margin: 0 1rem .5rem 0;

      table {
        width: 200px;
      }
    }
  }

  .rimg,
  .rimg:only-child {
    text-align: center;

    table {
      width: 100%;
    }

    @include breakpoint(medium) {
      float: right;
      margin: 0 0 .5rem 1rem;

      table {
        width: 200px;
      }
    }
  }

  .cimg {
    margin: 1rem;
    text-align: center;
  }

  img.rimg,
  img.limg,
  img.cimg {
    @include breakpoint(small only) {
      display: block !important;
      float: none !important;
      margin: 1rem auto !important;
    }
  }

  span.limg,
  span.rimg {
    background-color: $light-gray;
    color: $dark-gray;
    font-size: rem-calc(12);
    padding: 5px;
    text-align: center;
  }

  span.limg {
    clear: left;
  }

  .box-shadow-lb {
    box-shadow: -2px 2px 10px rgb(0 0 0 / 75%);
  }

  .box-shadow-rb {
    box-shadow: 2px 2px 10px rgb(0 0 0 / 75%);
  }

  table {
    border-collapse: collapse;
    border-color: $medium-gray;
    margin: 1rem 0;
    padding: 0;
    max-width: 100%;

    p {
      margin-bottom: 0;
    }

    @include breakpoint(medium) {
      h2,
      h3 {
        margin-top: 0;
      }
    }

    &.scroll {
      @include breakpoint(medium) {
        @include table-scroll;
      }
    }
  }

  caption {
    color: $dark-gray;
    text-align: right;
  }

  th {
    border: none;
    background-color: $light-gray;
    font-size: rem-calc(13);
    font-weight: $global-weight-normal;
    padding: math.div($global-padding, 2) $global-padding;
    text-align: center;

    &.lft {
      text-align: left;
    }

    &.rgt {
      text-align: right;
    }

    small {
      font-weight: $global-weight-normal;
    }
  }

  td {
    border: none;
    padding: math.div($global-padding, 2);

    &.idx {
      padding: 3px 5px 6px;
    }

    &.idx-empty {
      padding-top: $global-padding * 2;
      padding-bottom: $global-padding;
    }
  }

  h2,
  .h2 {
    @include small-h;

    @include breakpoint(small only) {
      background-color: $light-gray;
    }

    &.small-only-margin-top-20 {
      @include breakpoint(small only) {
        margin-top: 20px;
      }
    }

    a {
      @include color-mixin($light-black);

      text-decoration: underline;
    }
  }

  h3,
  .h3 {
    //font-weight: $global-weight-bold;
    //margin: $global-margin 0;

    @include small-h;
  }

  .pubdate {
    color: $dark-gray;
    font-size: rem-calc(12);
    padding: 0;
    margin-bottom: math.div($global-margin, 2);

    & > div {
      display: inline-block;
      margin-left: $global-margin;
    }

    a {
      color: $buy;
      text-decoration: underline;

      @include breakpoint(small only) {
        display: block;
      }
    }
  }

  .comment-link {
    color: #666 !important;

    .icon-comment {
      margin: 2px 6px 2px 0;
    }
  }

  .info {
    background-color: #ffc;
    display: block;
    margin: $global-margin 0;
    padding: $global-padding;
    width: auto;
    overflow: hidden;
  }

  ul ul,
  ol ul {
    list-style-type: circle;
  }

  ul.contents {
    margin-left: 0;
    list-style-type: none;
  }

  .contents {
    ul {
      margin-top: 0;
      margin-bottom: 0;
      list-style-type: none;
    }

    li {
      line-height: rem-calc(24);
    }
  }

  dl.contents {
    dl {
      margin-left: $global-margin;
    }

    dt.page {
      margin-top: 1rem;
      margin-bottom: 0;
    }

    dd {
      //line-height: rem-calc(24);

      @include breakpoint(small only) {
        padding-bottom: $global-padding;

        img {
          vertical-align: middle;
          margin-right: 5px;
        }
      }
    }

    @include small-padding;
  }

  blockquote {
    @include breakpoint(small only) {
      h2 {
        background-color: transparent;
        border-top: none;
        border-bottom: none;
      }
    }
  }

  .tizer {
    display: block;
    width: auto;
    margin: $global-margin 0;
    padding: $global-padding;
    background-color: #f8f6e7;

    p:last-child {
      margin-bottom: 0;
    }

    @include breakpoint(medium) {
      border-radius: $global-radius;
    }

    @include small-padding($global-padding);
  }

  .about,
  .aboutr {
    display: block;
    width: 300px;
    background-color: #eeecdd;
    padding: $global-padding;
  }

  .about {
    float: left;
    margin-right: $global-margin;
  }

  .aboutr {
    float: right;
    margin-left: $global-margin;
  }

  .search-tizer {
    font-size: rem-calc(13);
    color: map-get($foundation-palette, secondary);

    @include breakpoint(medium) {
      margin-bottom: $global-margin;
      margin-left: $global-margin;
    }

    @include small-padding(0 $global-padding $global-padding);
  }

  .not-found {
    margin: 0 $global-margin $global-margin * 2;
  }

  .vertical-align-middle {
    vertical-align: middle;
  }

  acronym {
    border-bottom: 1px dashed $primary-color;
    cursor: pointer;
    padding: 0 2px;
  }

  figure {
    margin-bottom: $global-margin;
    text-align: center;
  }

  figure,
  img {
    &.figure-left {
      margin: 0 $global-margin $global-margin 0;

      @include breakpoint(medium) {
        float: left;
        clear: both !important;
      }
    }

    &.figure-right {
      margin: 0 0 $global-margin $global-margin;

      @include breakpoint(medium) {
        float: right;
        clear: both !important;
      }
    }
  }

  figcaption {
    color: $dark-gray;
    font-style: italic;
    font-size: rem-calc(13);
    text-align: center;
  }

  @include breakpoint(medium) {
    .toolbar-fixed-box {
      top: 0 !important;
    }
  }
}

table.table {
  margin: 0 0 $global-margin;
  line-height: 18px;
  border: 2px solid $medium-gray;
}

.table {
  caption {
    font-size: rem-calc(12);
    font-weight: $global-weight-bold;
    text-align: right;
    line-height: 24px;
    color: $light-black;
  }

  th {
    background-color: #f2f2f2;
    font-weight: $global-weight-bold;
    border: 1px solid $medium-gray;
  }

  td {
    border: 1px solid $light-gray;
    font-size: rem-calc(14);

    &.ltl {
      font-size: rem-calc(12);
    }
  }

  &-row-white {
    background-color: $white;

    &:hover {
      background-color: #f0f0f0;
    }
  }

  &-row-gray {
    background-color: #f4f4f4;

    :hover {
      background-color: #f0f0f0;
    }
  }

  &.analytics {
    border: 1px solid #cacaca;
    width: 100%;

    td {
      text-align: center;

      &:first-child {
        text-align: left;
      }
    }

    tfoot {
      border-top: 1px $light-black solid;
      background-color: #f0f0f0;
      font-weight: $global-weight-bold;
    }
  }
}

;@include ---3m2zn08l3uw.load-css("sass-embedded-legacy-load-done:3827");