@use "sass:meta" as ---8miszvug6px;@use 'sass:math';

table.user-tizer {
  width: 100%;
  clear: both;
  margin-top: 0;

  @include grid-column-margin;

  tbody {
    border: none;
  }

  tr {
    border: none;
  }

  td {
    background-color: transparent;

    &.more {
      padding-top: $global-padding;

      .button {
        margin-bottom: 0;
      }

      .button + .button {
        margin-left: .5rem;
      }
    }
  }

  th {
    font-size: rem-calc(14);
  }

  @include breakpoint(small only) {
    margin: 0 0 $global-margin;

    th {
      background-color: $white;
      padding: .5rem 0 0;
      text-align: left;
      color: $dark-gray;
    }

    td {
      padding: 0;
      font-size: rem-calc(14);
    }

    @include table-stack;
  }

  @include breakpoint(medium) {
    th {
      width: 10rem;
      text-align: right;
      box-sizing: content-box;
    }

    th,
    td {
      padding: rem-calc(math.div($global-padding, 4) math.div($global-padding, 2));
    }

    td {
      font-size: rem-calc(14);

      [itemprop='name'] {
        font-size: 1rem;
        font-weight: $global-weight-bold;
      }
    }
  }
}

;@include ---8miszvug6px.load-css("sass-embedded-legacy-load-done:3866");