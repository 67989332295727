@use "sass:meta" as ---3m2zn08l3uw;@use 'sass:math';

.filter {
  margin-bottom: $global-margin;
  padding: math.div($global-padding, 2) $global-padding;
  border: 1px solid $medium-gray;
  background-color: #fafafa;

  @include breakpoint(medium) {
    box-shadow: 0 2px 0 #f4f4f4;
    border-radius: $global-radius;
    background-image: linear-gradient(to bottom, #fafafa, $light-gray);
  }

  label {
    font-size: rem-calc(13);
    margin: 3px 2px;
  }

  &__item {
    vertical-align: middle;

    @include breakpoint(medium) {
      display: inline-block;
    }

    input,
    select {
      display: inline-block;
      vertical-align: middle;
      margin: 0 0 $global-margin;

      @include breakpoint(medium) {
        margin: 0;
        height: 23px;
        font-size: rem-calc(12);
        border-radius: $global-radius;
      }
    }

    input {
      padding: 2px 5px;

      &[type='checkbox'] {
        height: inherit !important;
        vertical-align: inherit !important;
        width: inherit !important;
      }
    }

    select {
      padding: 2px $global-padding 2px 5px;
    }
  }

  &__button {
    background-color: $light-gray;
    background-image: linear-gradient(to top, $light-gray, #fefefe);
    color: $black;
    border: 1px solid $medium-gray;
    padding: 9px $global-padding !important;
    outline: none;

    @include breakpoint(medium) {
      padding: 0 math.div($global-padding, 2) !important;

      //margin-left: $global-margin !important;
    }

    &:hover,
    &:focus {
      color: $black;
    }

    &:focus {
      background-image: linear-gradient(to bottom, $light-gray, #fefefe);
    }
  }

  &_1 {
    .filter__item {
      &:nth-child(2),
      &:nth-child(4),
      &:nth-child(8),
      &:nth-child(10) {
        margin-right: $global-margin;
      }
    }

    @include breakpoint(medium down) {
      margin-top: $global-margin;

      .filter__item {
        display: inline;

        &:nth-child(2),
        &:nth-child(4),
        &:nth-child(8),
        &:nth-child(10) {
          margin-right: $global-margin;
        }

        &:last-child {
          display: block;
        }

        label[for='id_price_max'] {
          vertical-align: top;
          width: 10px;
          display: inline-block;
          margin-top: 7px;
        }
      }
    }
  }
}

;@include ---3m2zn08l3uw.load-css("sass-embedded-legacy-load-done:3832");