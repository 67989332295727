@use "sass:meta" as ---3m2zn08l3uw;.share-2 {
  i {
    margin-right: 5px;

    //vertical-align: middle;

    //@include breakpoint(large){
    vertical-align: bottom;

    //}
  }
}

;@include ---3m2zn08l3uw.load-css("sass-embedded-legacy-load-done:3856");