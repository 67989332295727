@use "sass:meta" as ---ullwtt3r34q;.publications-rubrica {
  @include breakpoint(medium) {
    margin-top: $global-margin;
    margin-bottom: $global-margin;
  }

  .grid-x {
    @include breakpoint(medium) {
      border-bottom: 1px solid #d3d3d3;
      margin-bottom: .5rem;
      padding-bottom: .5rem;

      &:last-child {
        border-bottom: 0;
      }
    }
  }

  &__title {
    color: $dark-gray;

    @include breakpoint(small only) {
      margin-bottom: .5rem;
    }
  }

  ul {
    @include breakpoint(medium) {
      display: flex;
      flex-wrap: wrap;
      list-style: none;
      margin-left: 0;
      margin-bottom: 0;
    }

    li {
      line-height: 1.8;
      margin-right: $global-margin * 2;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

;@include ---ullwtt3r34q.load-css("sass-embedded-legacy-load-done:3904");