@use "sass:meta" as ---rj7841chyf;.errorlist {
  display: flex;
  flex-flow: column;
  margin: -$global-margin 0 $global-margin;

  li {
    color: $alert-color;
    display: block;
    list-style-type: none;
    padding: $input-padding;
  }
}

;@include ---rj7841chyf.load-css("sass-embedded-legacy-load-done:3872");