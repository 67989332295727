@use "sass:meta" as ---3m2zn08l3uw;@use 'sass:math';

#collapse {
  @media print {
    display: none !important;
  }
}

@include breakpoint(small only) {
  #collapse {
    & > *:not(#collapse_button) {
      display: none;
    }
  }

  #collapse_button {
    position: relative;
    text-decoration: none;
    color: $black;
    box-sizing: border-box;
    font-weight: $global-weight-normal;

    &.expanded {
      font-weight: $global-weight-bolder;

      &::after {
        transform: rotate(180deg);
        transform-origin: 50% 50%;
      }
    }

    &::after {
      @include css-triangle(6px, $black, down);

      display: none;
      position: absolute;
      top: 50%;
      margin-top: -3px;
      right: 1rem;
    }

    &.visible {
      &::after {
        display: block;
      }
    }
  }

  h1#collapse_button {
    padding-right: 2.1rem;
  }

  a#collapse_button {
    display: block;
    padding: $global-padding;

    @include show-for-only(small);

    &.collapse_1 {
      padding: $global-padding 5px;
    }
  }
}

@include breakpoint(medium down) {
  #collapse-mob_button {
    &.expanded {
      &::after {
        transform: rotate(180deg);
        transform-origin: 50% 50%;
      }
    }

    &::after {
      @include css-triangle(3px, $white, down);

      display: inline-block;
      margin: 0 math.div($global-margin, 2);
      vertical-align: middle;
    }
  }
}

;@include ---3m2zn08l3uw.load-css("sass-embedded-legacy-load-done:3824");