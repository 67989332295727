@use "sass:meta" as ---3m2zn08l3uw;.partner-list {
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  &__logo {
    line-height: 0;
    margin: 1rem;
  }
}

;@include ---3m2zn08l3uw.load-css("sass-embedded-legacy-load-done:3847");