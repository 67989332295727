@use "sass:meta" as ---3m2zn08l3uw;.errorlist {
  display: flex;
  flex-flow: column;
  margin: -$global-margin 0 $global-margin;

  li {
    color: $alert-color;
    display: block;
    list-style-type: none;
    padding: $input-padding;
  }
}

;@include ---3m2zn08l3uw.load-css("sass-embedded-legacy-load-done:3872");