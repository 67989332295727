@use "sass:meta" as ---8miszvug6px;&.hashtags {
  ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    font-size: rem-calc(14);
    font-weight: $global-weight-bolder;

    li {
      flex-shrink: 0;
      background-color: $alice-blue;
      border-radius: $global-radius;
      padding: .5rem 1rem;
      margin: .6rem .6rem 0 0;

      &:hover {
        cursor: pointer;
        background-color: map_get($foundation-palette, san-marino);

        a {
          color: $white;
        }
      }
    }
  }
}

;@include ---8miszvug6px.load-css("sass-embedded-legacy-load-done:3908");