@use "sass:meta" as ---8miszvug6px;label,
legend {
  &.required {
    &::after {
      color: $alert-color;
      content: ' *';
    }
  }
}

input#id_title {
  font-weight: $global-weight-bolder;
}

input[data-date-picker] {
  max-width: rem-calc($fdatepicker-inline-width - 2) - $input-padding;
}

textarea#id_tizer {
  height: 5rem;
}

textarea#id_text {
  height: 20rem;
  padding: $global-padding;
  font-size: rem-calc(15);
  line-height: $global-lineheight;
}

button {
  outline: none;
}

.cell {
  input[type='checkbox'],
  input[type='radio'] {
    height: 20px;
    margin-bottom: 0;
    vertical-align: text-bottom;
    width: 20px;
  }

  [type='checkbox'] + label,
  [type='radio'] + label {
    font-size: inherit;
    line-height: 30px;
  }
}

.cell.form-choices {
  label {
    line-height: 1;
    margin-bottom: $form-spacing;
  }
}

.cell.form-actions {
  margin-top: $global-margin;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;

  .button {
    margin-bottom: 0;
  }
}

;@include ---8miszvug6px.load-css("sass-embedded-legacy-load-done:3870");