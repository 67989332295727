@use "sass:meta" as ---rj7841chyf;.help-parts {
  .part {
    border-bottom: 1px solid $medium-gray;
    padding: $global-padding 0;

    a {
      font-size: rem-calc(20);
      font-weight: $global-weight-bolder;
    }
  }
}

;@include ---rj7841chyf.load-css("sass-embedded-legacy-load-done:3892");