@use "sass:meta" as ---3m2zn08l3uw;@use 'sass:math';

header {
  @include show-for(medium);

  padding: $global-padding 0;

  @include breakpoint(medium only) {
    padding-bottom: 0;
  }

  .header {
    &-top {
      font-size: rem-calc(13);
      display: flex;
      justify-content: space-between;
      gap: 1rem;
      margin-bottom: $global-margin;
      white-space: nowrap;

      a {
        color: $light-black;

        &:hover {
          color: map-get($foundation-palette, alert);
        }
      }

      .socials {
        display: flex;
        gap: .5rem;

        a {
          background-repeat: no-repeat;
          background-size: 18px;
          display: block;
          height: 18px;
          width: 18px;

          span {
            position: absolute;
            width: 1px;
            height: 1px;
            clip: rect(1px, 1px, 1px, 1px);
          }
        }
      }

      .service {
        display: flex;
        gap: .5rem;
      }

      .create {
        display: flex;
        gap: .25rem;
        letter-spacing: -.05em;
        overflow-x: hidden;

        div {
          &.title {
            color: map-get($foundation-palette, primary);

            &::after {
              color: map-get($foundation-palette, primary);
              content: ':';
              margin-right: .25rem;
            }
          }

          &.item {
            &:not(:last-child, :nth-child(n+10)) {
              &::after {
                color: $medium-gray;
                content: '|';
                margin-left: .25rem;
              }
            }
          }

          &:nth-child(n+11) {
            display: none;
          }
        }
      }
    }

    &-main {
      display: flex;
      align-items: center;
      gap: 2rem;

      .logo {
        display: contents;

        a {
          line-height: 0;
          vertical-align: top;

          text {
            fill: $dark-gray;
            font-size: rem-calc(14.3);
            text-transform: lowercase;
          }
        }
      }

      .search {
        flex-basis: 100%;

        .search-form {
          display: flex;
          flex-grow: 1;
          flex-shrink: 1;

          &__input {
            border-radius: $global-radius 0 0 $global-radius;
            border-right: none;
            height: 35px;
            padding-left: 1rem;
            margin-bottom: 0;
          }

          &__button {
            border-radius: 0 $global-radius $global-radius 0;
            background-color: $dark-gray;
            color: $white;
            height: 35px;
            padding: 0 1rem;
            position: relative;
            flex-shrink: 0;

            &:hover {
              background-color: darken($dark-gray, 10%);
              cursor: pointer;
            }

            span {
              padding-left: 16px + 7px;

              &::before {
                position: absolute;
                background-size: 16px;
                background-image: url('svg/search-button-inline.svg');
                content: '';
                height: 16px;
                left: 16px;
                width: 16px;
                vertical-align: text-bottom;
              }
            }
          }
        }
      }

      .metz-special {
        flex-shrink: 0;

        .inner {
          background: #2e3191;
          background: linear-gradient(90deg, #2e3191 0%, #015098 100%);
          padding: 4px 10px;
          border-radius: $global-radius;
          height: 35px;
          display: flex;
          gap: .3rem;
          align-items: center;

          &:hover {
            background: #0156a3;
          }

          &::before {
            content: '';
            display: block;
            color: $white;
            background-image: url('./svg/logo-metz-v2.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            width: 86px;
            height: 24px;
          }
        }

        span {
          color: $white;
          font-size: rem-calc(14);
        }
      }

      .user {
        font-size: rem-calc(14);

        &__anonymous {
          display: flex;
          justify-content: flex-end;
          height: 35px;

          .sign-up,
          .sign-in {
            align-items: center;
            justify-content: center;
            border: 1px solid map-get($foundation-palette, primary);
            border-radius: $global-radius;
            display: inherit;
            padding: 0 1rem;
            width: 115px;
          }

          .sign-up {
            &:hover {
              background-color: darken($white, 5%);
              color: map-get($foundation-palette, primary);
            }
          }

          .sign-in {
            background-color: map-get($foundation-palette, primary);
            color: $white;

            &:hover {
              background-color: darken(desaturate(#2e3191, .2), 7.45);
            }
          }

          a + a {
            margin-left: 10px;
          }
        }

        &__panel {
          align-items: center;
          display: flex;
          margin: -10px 0 -10px -10px;
          padding: 10px 0 10px 10px;
          position: relative;
          width: 240px;

          &.hover {
            box-shadow: 0 0 10px 1px $medium-gray;
          }

          &__menu {
            margin-left: 10px;
          }

          &__avatar {
            cursor: pointer;
            position: relative;

            img {
              border-radius: 50%;
              box-shadow: 0 0 0 1px $medium-gray;
              box-sizing: content-box;
              vertical-align: top;
            }
          }

          .unread {
            line-height: 0;
            vertical-align: middle;

            svg {
              circle {
                fill: $alert-color;
              }

              text {
                font-weight: $global-weight-bolder;
                font-size: 12px;
                fill: $white;
                text-anchor: middle;
              }
            }
          }

          .username {
            display: block;
            max-width: 178px;
            overflow: hidden;
            font-weight: $global-weight-bold;
            text-overflow: ellipsis;
            white-space: nowrap;

            @include color-mixin($black);
          }

          .cplink {
            white-space: nowrap;

            &__arrow {
              cursor: pointer;
              color: $dark-gray;
              font-size: rem-calc(11);
              display: inline-block;
            }
          }

          .cpmenu {
            display: none;
            position: absolute;
            box-shadow: 0 1px 0 1px $medium-gray;
            border-radius: $global-radius;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 240px;
            background-color: $white;
            z-index: 1001;
            padding-top: 10px;
            padding-bottom: 10px;
            left: 0;

            a {
              margin: 0 5px;
              display: block;
              padding: 5px;
              max-width: 100%;
              box-sizing: border-box;
              border-bottom: 1px solid $light-gray;

              @include color-mixin($light-black);

              &:last-child {
                border-bottom: none;
              }

              &:hover {
                box-shadow: 0 0 0 1px $primary-color;
                color: $primary-color;
              }

              span {
                color: $alert-color;
                font-size: rem-calc(11);
                font-weight: $global-weight-bolder;
                margin-left: .5rem;
              }

              &.admin {
                @include color-mixin(map-get($foundation-palette, secondary));

                font-weight: $global-weight-bolder;

                &:hover {
                  box-shadow: 0 0 0 1px map-get($foundation-palette, secondary);
                }
              }

              &.index {
                @include color-mixin($primary-color);

                font-weight: $global-weight-bolder;
              }

              &.exit {
                @include color-mixin($alert-color);

                &:hover {
                  box-shadow: 0 0 0 1px $alert-color;
                }
              }
            }
          }
        }
      }
    }
  }
}

;@include ---3m2zn08l3uw.load-css("sass-embedded-legacy-load-done:3834");